<script lang="ts">
export default {
  name: 'PopoverMenuButton',
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { defineProps } from 'vue';
import Chevron from '@/components/atoms/NavigationChevron/Index.vue';

interface PopoverMenuButton {
  icon?: string;
  label: string;
}
defineProps<{ item: PopoverMenuButton; hasSubMenu?: boolean; active?: boolean }>();
</script>

<template>
  <button class="popover-menu-item__button" :class="{ active }">
    <FontAwesomeIcon v-if="item.icon" :icon="`${item.icon} fa-regular`" size="lg" class="fa-fw" />
    <span class="popover-menu-item__button-text">{{ item.label }}</span>
    <Chevron v-if="hasSubMenu" direction="right" theme="light" size="small" />
  </button>
</template>

<style lang="scss" scoped>
.popover-menu-item__button {
  all: unset;
  color: $black;
  display: flex;
  flex: 1;
  width: 100%;
  gap: 1rem;
  align-items: center;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  user-select: none;

  &.active,
  &:hover {
    background-color: $platinum;
  }

  .popover-menu-item__button-text {
    flex: 1;
  }
}
</style>
