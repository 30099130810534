<script lang="ts">
export default {
  name: 'NavigationItem',
  // Remove when all components migrated to Vue 3.
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { withDefaults } from 'vue';
import NavigationChevron from '@/components/atoms/NavigationChevron/Index.vue';

import { defineProps } from 'vue';
interface Props {
  chevronBefore?: boolean;
  chevronAfter?: boolean;
  theme?: 'branded' | 'light';
}

withDefaults(defineProps<Props>(), {
  theme: 'branded',
});
</script>

<template>
  <li class="navigation-item__list-item">
    <NavigationChevron v-if="chevronBefore" :theme="theme" direction="left" />
    <slot />
    <NavigationChevron v-if="chevronAfter" :theme="theme" />
  </li>
</template>

<style lang="scss" scoped>
.navigation-item__list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  padding: 10px 34px;

  & :deep(button) {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }
}
</style>
