<script lang="ts">
export default {
  name: 'NavigationChevron',
  // Remove when all components migrated to Vue 3.
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { defineProps, withDefaults } from 'vue';

interface Props {
  direction?: 'up' | 'down' | 'left' | 'right';
  theme?: 'branded' | 'light';
  size?: 'small' | 'medium' | 'large';
}

withDefaults(defineProps<Props>(), {
  direction: 'right',
  theme: 'branded',
  size: 'medium',
});
</script>

<template>
  <div
    class="chevron"
    :class="{
      [direction]: true,
      [size]: true,
      'chevron--light': theme === 'light',
      'chevron--branded': theme === 'branded',
    }"
  ></div>
</template>

<style lang="scss" scoped>
.chevron {
  clip-path: polygon(40% 0%, 90% 50%, 40% 100%, 24% 85%, 60% 50%, 25% 15%);

  &.left {
    transform: rotate(180deg);
  }
  &.up {
    transform: rotate(-90deg);
  }
  &.down {
    transform: rotate(90deg);
  }

  &.small {
    width: 1rem;
    height: 1rem;
  }
  &.medium {
    width: 2rem;
    height: 2rem;
  }
  &.large {
    width: 3rem;
    height: 3rem;
  }
}

.chevron--branded {
  background-color: var(--navigation-text-color);
}

.chevron--light {
  background-color: $jet;
}
</style>
